<template>
  <div>
    <el-card v-loading="loading" class="order-detail-container x-card-title" header="开票明细">
      <div slot="header" class="clearfix">
        <span>开票明细</span>
        <el-button @click="() => { $router.go(-1); }" size="small" class="back_btn">
          <img src="@/assets/back.png" alt="">
        </el-button>
      </div>
      <div class="cont" style="display: flex;">
        <div style="width: 40%;">
          <p>
            <span>申请发票金额：</span>
            <span>￥{{ details.receipt_amount }}</span>
          </p>
          <p>
            <span>申请发票类型：</span>
            <span>
              {{ details.receipt_type === "ELECTRO" ?
                "电子普通发票" : details.receipt_type === "VATORDINARY" ?
                  '增值税普通发票' : "增值税专用发票" }}
            </span>
          </p>
          <p>
            <span>发&nbsp;&nbsp;票&nbsp;&nbsp;抬&nbsp;&nbsp;头：</span>
            <span>{{ details.receipt_title }}</span>
          </p>
          <p>
            <span>企&nbsp;&nbsp;业&nbsp;&nbsp;税&nbsp;&nbsp;号：</span>
            <span>{{ details.tax_no }}</span>
          </p>
          <p>
            <span>开&nbsp;&nbsp;户&nbsp;&nbsp;银&nbsp;&nbsp;行：</span>
            <span v-if="details.receipt_type === 'VATOSPECIAL'">{{ details.bank_name }}</span>
          </p>
          <p>
            <span>开&nbsp;&nbsp;户&nbsp;&nbsp;账&nbsp;&nbsp;号：</span>
            <span v-if="details.receipt_type === 'VATOSPECIAL'">{{ details.bank_account }}</span>
          </p>
        </div>
        <div style="width: 40%;">
          <p>
            <span>申&nbsp;&nbsp;请&nbsp;&nbsp;时&nbsp;&nbsp;间：</span>
            <span>{{ details.create_time | unixToDate("yyyy-MM-dd hh:mm:ss") }}</span>
          </p>
          <p>
            <span>发&nbsp;&nbsp;票&nbsp;&nbsp;状&nbsp;&nbsp;态：</span>
            <span v-if="details.status === -1">审核未通过</span>
            <span v-if="details.status === 0">审核中</span>
            <span v-if="details.status === 1">待邮寄</span>
            <span v-if="details.status === 2">已邮寄</span>
            <span v-if="details.status === 3">已成功</span>
          </p>
          <p>
            <span>收&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;件&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;人：</span>
            <span>{{ details.receive_name }}</span>
          </p>
          <p>
            <span>联&nbsp;&nbsp;系&nbsp;&nbsp;电&nbsp;&nbsp;话：</span>
            <span>{{ details.receive_phone }}</span>
          </p>
          <p>
            <span>收&nbsp;&nbsp;件&nbsp;&nbsp;地&nbsp;&nbsp;址：</span>
            <span>{{ details.receive_addr }}</span>
          </p>
        </div>
      </div>
      <div class="tables">
        <div v-if="type === 1 || type === 7">
          <en-table-layout :toolbar="true" :table-data="tableData.data" :span-method="type1SpanMethod">
            <template slot="toolbar" v-if="details.receipt_type !== 'ELECTRO'">
              <el-button type="primary" @click="handleExportOrders">导出</el-button>
            </template>
            <template slot="table-columns">
              <el-table-column label="关联单号" prop="trade_sn" width="150"></el-table-column>
              <el-table-column label="子订单编号" prop="sn" width="150"></el-table-column>
              <el-table-column label="完成时间" width="200">
                <template slot-scope="{row}">
                  {{ row.complete_time | unixToDate }}
                </template>
              </el-table-column>
              <el-table-column label="总价" prop="order_price" width="100" v-if="details.billing_id !== 0">
                <template slot-scope="{row}">
                  {{ row.supplier_order_price | unitPrice("￥") }}
                </template>
              </el-table-column>
              <el-table-column label="总价" prop="order_price" width="100" v-else>
                <template slot-scope="{row}">
                  {{ row.order_price | unitPrice("￥") }}
                </template>
              </el-table-column>
              <el-table-column label="可开具金额" width="100" v-if="details.billing_id !== 0">
                <template slot-scope="{row}">
                  {{ row.supplier_order_price - row.supplier_refund_price | unitPrice("￥") }}
                </template>
              </el-table-column>
              <el-table-column label="可开具金额" width="100" v-else>
                <template slot-scope="{row}">
                  {{ row.order_price - row.order_refund_price | unitPrice("￥") }}
                </template>
              </el-table-column>
              <el-table-column label="商品名称" prop="name" min-width="200" show-overflow-tooltip>
                <template slot-scope="{row}">
                  {{ row.name.replaceAll('amp;', '').replaceAll(' ', '&nbsp;') }}
                </template>
              </el-table-column>
              <el-table-column label="单价" width="100" v-if="details.billing_id !== 0">
                <template slot-scope="{row}">
                  {{ row.supplier_purchase_price ? row.supplier_purchase_price : 0 | unitPrice("￥") }}
                </template>
              </el-table-column>
              <el-table-column label="单价" width="100" v-else>
                <template slot-scope="{row}">
                  {{ row.original_price ? row.original_price : 0 | unitPrice("￥") }}
                </template>
              </el-table-column>
              <el-table-column label="数量" prop="num" width="50"></el-table-column>
              <el-table-column label="操作" v-if="details.receipt_type === 'ELECTRO' && details.billing_id === 66"
                width="80">
                <template slot-scope="{row}">
                  <el-button type="text" @click="clickShowElcInfo(row.sn)">查看发票</el-button>
                </template>
              </el-table-column>
            </template>

            <el-pagination background slot="pagination" @size-change="handlePageSizeChange"
              @current-change="handlePageCurrentChange" :current-page="tableData.page_no"
              :page-size="tableData.page_size" :layout="MixinTableLayout" :page-sizes="MixinPageSizes"
              :total="tableData.data_total"></el-pagination>
          </en-table-layout>
        </div>
        <div v-if="type === 2">
          <en-table-layout :toolbar="false" :table-data="tableData.data">
            <template slot="table-columns">
              <el-table-column prop="sn" label="订单号" width="150"></el-table-column>
              <el-table-column prop="create_time" label="完成时间" width="200">
                <template slot-scope="{row}">
                  {{ row.create_time | unixToDate }}
                </template>
              </el-table-column>
              <el-table-column prop="service_name" label="服务名称"></el-table-column>
              <el-table-column prop="price" label="可开具金额"></el-table-column>
            </template>

            <el-pagination background slot="pagination" @size-change="handlePageSizeChange"
              @current-change="handlePageCurrentChange" :current-page="tableData.page_no"
              :page-size="tableData.page_size" :layout="MixinTableLayout" :page-sizes="MixinPageSizes"
              :total="tableData.data_total"></el-pagination>
          </en-table-layout>
        </div>
        <div v-if="type === 3">
          <en-table-layout :toolbar="false" :table-data="tableData.data">
            <template slot="table-columns">
              <el-table-column prop="trade_sn" label="订单号" align="center" width="150" />
              <el-table-column prop="create_time" label="完成时间" align="center" width="200">
                <template slot-scope="{row}">
                  {{ row.create_time | unixToDate }}
                </template>
              </el-table-column>
              <el-table-column prop="platform_freight_price" label="运费金额" align="center" />
              <el-table-column prop="platform_freight_price" label="可开具金额" align="center" />
            </template>
            <el-pagination background slot="pagination" @size-change="handlePageSizeChange"
              @current-change="handlePageCurrentChange" :current-page="tableData.page_no"
              :page-size="tableData.page_size" :layout="MixinTableLayout" :page-sizes="MixinPageSizes"
              :total="tableData.data_total"></el-pagination>
          </en-table-layout>
        </div>
      </div>
      <!--    :page-sizes="MixinPageSizes"-->
      <div class="cont mt-3" v-if="details.receipt_type !== 'ELECTRO'">
        <div style="display: flex; align-items: center;">
          <h3>物流详情 </h3>
          <span
            style="font-size: 12px; color: #999999; display: block">（提示：当前系统上发票的物流信息可能和实际邮寄发票的物流信息存在差异，请以实际物流为准。）</span>
        </div>
        <template v-if="!details.logi_name && !details.logi_id && details.status !== 2">
          （暂无）
        </template>
        <template v-else>
          <el-steps direction="vertical" :active="0">
            <el-step v-for="(item, index) in logisticsData.data" :title="item.context" :description="item.time"
              :key="index"></el-step>
          </el-steps>
        </template>
      </div>
      <div class="cont mt-3" v-else>
        <h3>发票详情</h3>
        <div style="display: flex; flex-flow: wrap">
          <div style="margin-right: 10px;" v-for="(item, index) in fileList" :key="index">
            <img src="@/assets/zip.jpeg" alt="" v-if="item.file_type === 'zip' || item.file_type === 'rar'">
            <img src="@/assets/pdf.png" alt="" v-if="item.file_type === 'pdf'"><br>
            <el-tooltip class="fileName" effect="dark" :content="item.file_name" placement="top-start">
              <span>{{ item.file_name }}</span>
            </el-tooltip>
          </div>
        </div>
      </div>
    </el-card>
    <x-dialog :proxy="dialogProxy">
      <iframe ref="iframe" src="" frameborder="0" style="width: 100%; height: 650px;"></iframe>
      <div style="margin-top: 10px; margin-bottom: 10px;">
        <img :class="[active === index ? 'active_img' : '']" src="@/assets/fp.jpeg" alt=""
          v-for="(item, index) in invoice_list" :key="index" style="width: 150px; height: 100px; margin-right: 20px;"
          @click="handlerImgChange(index)">
      </div>
    </x-dialog>
  </div>
  <!-- </div> -->
</template>

<script>
import * as API_Invoice from "@/api/invoice";
import * as API_Account from "@/api/account";
import EnTableLayout from "../../../../ui-components/TableLayout/src/main";
import { Foundation } from "@/../ui-utils";
import XDialog from '@/components/x-dialog/x-dialog.vue';
import { $xDialog } from '@/components/x-dialog/dialog.proxy';

export default {
  name: "auditHistoryDetail",
  components: { EnTableLayout, XDialog },
  data () {
    return {
      id: "",
      type: 0,
      details: {},
      params: {
        page_no: 1,
        page_size: 20,
        orderstr: "",
        type: "",
      },
      tableData: { data: [] },
      /** 列表loading状态 */
      loading: false,
      /** 物流信息 */
      logisticsData: [],
      list: [],
      fileList: [],
      billing_id: '',
      invoice_list: [],
      dialogProxy: $xDialog.create({
        title: '发票详情',
        disableConfirm: true,
        disableCancel: true,
        showClose: true,
        width: '90vw',
      }),
      active: 0,
      exportNum: 0,
      exportData: []
    };
  },
  mounted () {
    this.id = this.$route.query.id;
    this.type = parseInt(this.$route.query.type + '');
    this.getInfo();
  },
  methods: {
    type1SpanMethod ({ row, column, rowIndex, columnIndex }) {
      if (columnIndex > 3 && columnIndex < 8) {
        return {
          rowspan: 1,
          colspan: 1,
        };
      } else {
        return row.span;
      }
    },
    getInfo () {
      API_Invoice.getInfo(this.id).then((res) => {
        this.details = res;
        if (res.file) {
          res.file.split(',').filter(item => {
            return item !== ''
          }).forEach(i => {
            let obj = {}
            obj['file_type'] = i.split('/').slice(-1)[0].split('.')[1]
            obj['file_name'] = i.split('/').slice(-1)[0]
            obj['url'] = i
            this.fileList.push(obj)
          })
        }
      }).then(() => {
        this.GET_Express();
        this.getOrderList();
      })
    },
    // 查看电子发票
    clickShowElcInfo (sn) {
      API_Invoice.showElcInfo(sn).then(res => {
        this.invoice_list = res
        this.$nextTick(() => {
          this.$refs['iframe'].src = res[0].replace(/amp;/g, '').replace('http', 'https')
        })
        this.dialogProxy.display()
      })
    },
    handlerImgChange (index) {
      this.active = index
      this.$nextTick(() => {
        this.$refs['iframe'].src = this.invoice_list[index].replace(/amp;/g, '').replace('http', 'https')
      })
    },
    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.page_no = 1
      this.params.page_size = size;
      this.getInfo();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page;
      this.getInfo();
    },
    getOrderList () {
      this.params.type = this.type;
      let params = JSON.parse(JSON.stringify(this.params))
      delete params.orderstr
      params.history_id = this.id
      API_Invoice.getOrderListNew(params).then((res) => {
        this.tableData = res;

        if (this.type === 1 || this.type === 7) {
          this.tableData.data = res.data.reduce((data, item) => {
            let subItems = JSON.parse(item.items_json);
            subItems = subItems.map((subItem, index) => {
              let span = {
                rowspan: 0,
                colspan: 0,
              };

              if (index === 0) {
                span = {
                  rowspan: subItems.length,
                  colspan: 1,
                }
              }

              return {
                ...item,
                ...subItem,
                span,
              };
            });
            delete item.items_json;
            data.push(...subItems);
            return data;
          }, []);
        }
      });
    },
    GET_Express () {
      if (!this.details.logi_id && this.details.ext_mark_id && this.details.status === 2) {
        let params = {}
        params['mark_id'] = this.details.ext_mark_id.split(',')[0]
        API_Invoice.getJDExpress(params).then(res => {
          this.logisticsData = res;
        })
      }
      if (this.details.logi_name && this.details.logi_id) {
        // API_Invoice.getExpressByHistoryId({
        //   history_id: this.id,
        // }).then((res) => {
        //   this.logisticsData = res;
        // });
        API_Account.getExpressDetail({
          id: this.details.logi_id, //快递公司
          num: this.details.delivery_no, //物流单号
          mobile: this.details.receive_phone//电话
        }).then(res => {
          this.logisticsData = res;
        });
      }
    },
    handleExportOrders () {
      this.exportNum = 0
      this.exportData = []
      this.loading = true
      let arr = this.params.orderstr.split(',')
      let len = 5000  // 一次查询数量
      for (let i = 1; i <= Math.ceil(arr.length / len); i++) {
        let params = {
          page_no: 1,
          page_size: 99999,
          type: this.params.type,
          history_id: this.id
        };
        API_Invoice.getOrderListNew(params).then(res => {
          this.exportNum++
          this.exportData = [...this.exportData, ...res.data]
          if (this.exportNum % 7 === 0 || this.exportNum === Math.ceil(arr.length / len)) {
            this.orderListHandle(this.exportData)
            this.exportData = []
          }
          if (this.exportNum === Math.ceil(arr.length / len)) this.loading = false
        })
      }
    },
    orderListHandle (resData) {
      let data = []
      resData.forEach(item => {
        let tmp_obj = {}
        tmp_obj["trade_sn"] = item.trade_sn
        tmp_obj["sn"] = item.sn
        tmp_obj["complete_time"] = Foundation.unixToDate(item.complete_time, 'yyyy-MM-dd hh:mm:ss');
        tmp_obj["order_price"] = item.order_price
        tmp_obj["order_refund_price"] = item.order_price - item.order_refund_price
        item["items_json"] = JSON.parse(item["items_json"])
        if (item["items_json"].length > 1) {
          item["items_json"].forEach(item_1 => {
            let obj = {}
            obj["trade_sn"] = item.trade_sn
            obj["sn"] = item.sn
            obj["complete_time"] = Foundation.unixToDate(item.complete_time, 'yyyy-MM-dd hh:mm:ss');
            obj["order_price"] = item.order_price
            obj["order_refund_price"] = item.order_price - item.order_refund_price
            obj["name"] = item_1["name"]
            obj["original_price"] = item_1["original_price"]
            obj["num"] = item_1["num"]
            data.push(obj)
          })
        } else {
          tmp_obj["name"] = item["items_json"][0]["name"]
          tmp_obj["original_price"] = item["items_json"][0]["original_price"]
          tmp_obj["num"] = item["items_json"][0]["num"]
          data.push(tmp_obj)
        }
      });
      const json = {
        sheet_name: "发票订单列表",
        sheet_values: data.map((item) => ({
          关联单号: item.trade_sn,
          子订单编号: item.sn,
          订单完成时间: item.complete_time,
          总价: Foundation.formatPrice(item.order_price),
          可开具金额: Foundation.formatPrice(item.order_refund_price),
          商品名称: item.name,
          单价: item.original_price,
          数量: item.num
        })),
      };
      this.MixinExportJosnToExcel(json, "发票订单列表");
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .active_img {
  border: 1.5px solid black;
}

::v-deep .el-step__title.is-wait {
  color: #666;
}

.order-detail-container {}

.back_btn {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #1A43A9;
  float: right;

  img {
    width: 10px;
    height: 20px;
    position: relative;
    top: -2px;
    left: -4px;
  }
}

.cont {
  /* width: 1400px; */
  /* margin-left: 80px; */
  margin-left: 30px;
  padding-bottom: 20px;
}

/* ::v-deep .is-leaf{
   border: 1px solid #000000 !important; box-sizing: border-box; text-align: center;font-size: 16px; } */
/* ::v-deep .el-table__row td { border: 1px solid #000000 !important; text-align: center; font-size: 16px; } */
/* ::v-deep .el-table--border { border:
   1px solid #000000 !important; } */
.zongji {
  width: 100%;
  font-size: 26px;
  text-align: right;
  padding-top: 30px;
}

.sendhuo {
  margin-top: 60px;
  float: right;
}

.goods-image {
  width: 50px;
  height: 50px;
}

.el-table-tr {
  width: 100%;
  background-color: rgb(230, 236, 247);
  text-align: center;
  color: #909399;
  font-weight: 500;
}

.el-table-tr th {
  border: 1px solid #dcdfe6;
  height: 50px;
}

.el-table_3_column_1 {
  flex: 4;
}

.el-table_3_column_2 {
  flex: 4;
}

.el-table_3_column_3 {
  flex: 2;
}

.el-table_3_column_4 {
  flex: 2;
}

.el-table_3_column_5 {
  flex: 4;
}

.el-table_3_column_6 {
  flex: 2;
}

.el-table_3_column_7 {
  flex: 2;
}

.el-table-yuepp {
  width: 100%;
  border: 1px solid #dcdfe6;
}

td {
  text-align: center;
  height: 60px;
  border: 1px solid #dcdfe6;
  font-size: 14px;
  color: #606266;
}
</style>
